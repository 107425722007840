import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html, body, #___gatsby, #gatsby-focus-wrapper {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
  body {
    position: relative;
    font-size: 16px;
    font-family: 'Neurial Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  body * {
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
  }

  img {
    max-width: 100%;
    display: block;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.66em;
    font-weight: 600;
    line-height: 1.5;
    color: inherit;
  }

  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.875rem;
  }

  a {
    text-decoration: none;
  }

  ul, ol {
    list-style: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }
`

export default GlobalStyles
