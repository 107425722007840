import React from 'react'

export const useMediaQuery = (query: string): boolean => {
  const [match, setMatch] = React.useState(false)

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const handler = () => {
        setMatch(window.matchMedia(query).matches)
      }
      handler()
      window.matchMedia(query).addListener(handler)
      return () => {
        window.matchMedia(query).removeListener(handler)
      }
    }
  }, [query])
  return match
}
