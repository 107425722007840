import React from 'react'
import GlobalStyles from './GlobalStyles'
import '../../static/fonts/fonts.css'
import { ViewportProvider } from '../contexts/ViewportContext'

type Props = {}

export const RootElement: React.FC<Props> = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalStyles />
      <ViewportProvider>{children}</ViewportProvider>
    </React.Fragment>
  )
}

export default RootElement
