import React from 'react'
import { useMediaQuery } from '../hooks/useMediaQuery'

export type ViewportKeys =
  | 'viewport3'
  | 'viewport7'
  | 'viewport10'
  | 'viewport12'
  | 'viewport14'
  | 'viewport16'

type ViewportContext = {
  innerWidth: number
  innerHeight: number
  viewport: ViewportKeys
}

const ViewportContext = React.createContext<string | ViewportContext>(
  'useViewport should be used inside ViewportProvider',
)

type Props = {}

export const ViewportProvider: React.FC<Props> = ({ children }) => {
  const [viewportSize, setViewportSize] = React.useState({ innerWidth: 0, innerHeight: 0 })
  const isViewport7 = useMediaQuery('(min-width: 768px) and (orientation: portrait)')
  const isViewport10 = useMediaQuery('(min-width: 1024px) and (orientation: portrait)')
  const isViewport12 = useMediaQuery('(min-width: 1280px)')
  const isViewport14 = useMediaQuery('(min-width: 1440px)')
  const isViewport16 = useMediaQuery('(min-width: 1680px)')
  React.useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const handler = () => {
        setViewportSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
      }
      handler()
      window.addEventListener('resize', handler)
      window.addEventListener('orientationchange', handler)
      return () => {
        window.removeEventListener('resize', handler)
        window.removeEventListener('orientationchange', handler)
      }
    }
  }, [])
  const getViewport = () => {
    if (isViewport16) {
      return 'viewport16'
    }
    if (isViewport14) {
      return 'viewport14'
    }
    if (isViewport12) {
      return 'viewport12'
    }
    if (isViewport10) {
      return 'viewport10'
    }
    if (isViewport7) {
      return 'viewport7'
    }
    return 'viewport3'
  }
  return (
    <ViewportContext.Provider value={{ viewport: getViewport(), ...viewportSize }}>
      {children}
    </ViewportContext.Provider>
  )
}

export const useViewport = (): ViewportContext => {
  const context = React.useContext(ViewportContext)
  if (typeof context === 'string') {
    throw new Error(context)
  }
  return context
}
